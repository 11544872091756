function getAttributes(link) {
  return {
    hotelId: link.getAttribute("data-hotel-id"),
    lid: link.getAttribute("data-lid"),
    locationId: link.getAttribute("data-location-id"),
    locationType: link.getAttribute("data-location-type"),
    query: link.getAttribute("data-query"),
    specId: link.getAttribute("data-spec-id"),
  };
}

function handleLink(event) {
  event.preventDefault();
  const link = event.currentTarget;
  let url;
  switch (link.getAttribute("data-url-type")) {
    case "hotellist_spec":
      url = hotellistSpecUrl(link);
      break;
    case "hotellist_location":
      url = hotellistLocationUrl(link);
      break;
    case "hotellist":
      url = hotellistUrl(link);
      break;
    case "hotels_plans":
      url = hotelsPlansUrl(link);
      break;
    case "aggregated_hotels_plans":
      url = aggregatedHotelsPlansUrl(link);
      break;
    case "hotellist_result":
      url = hotellistResultUrl(link);
      break;
  }
  if (url?.trim()) {
    openUrl(url, link.getAttribute("data-target"));
  }
}

function hotellistSpecUrl(link) {
  const { locationType, locationId, specId } = getAttributes(link);
  return `/hotellist-${locationType}-${locationId}-${specId}`;
}

function hotellistLocationUrl(link) {
  const { locationType, locationId, query, lid } = getAttributes(link);
  return `/hotellist-${locationType}-${locationId}${query ? `?${query}` : ""}${lid ? `&lid=${lid}` : ""}`;
}

function hotellistUrl(link) {
  const { query, lid } = getAttributes(link);
  return `/hotellist${query ? `?${query}` : ""}${lid ? `&lid=${lid}` : ""}`;
}

function hotelsPlansUrl(link) {
  const { hotelId, query } = getAttributes(link);
  return `/hotels/${hotelId}/plans${query ? `?${query}` : ""}`;
}

function aggregatedHotelsPlansUrl(link) {
  const { hotelId, query } = getAttributes(link);
  return `/hotels-${hotelId}/plans${query ? `?${query}` : ""}`;
}

function hotellistResultUrl(link) {
  const { query, lid } = getAttributes(link);
  return lid ? `/hotellist/result?${query}&lid=${lid}` : `/hotellist/result?${query}`;
}

function openUrl(url, target) {
  target === "true" ? window.open(url, "_blank", "noopener") : (window.location.href = url);
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".js-link").forEach((link) => {
    link.addEventListener("click", handleLink);
    link.style.cursor = "pointer";
  });
});

export { handleLink };
