import { updateOutput } from "javascripts/webpage/destination";

document.addEventListener("DOMContentLoaded", () => {
  const resetForm = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const formRange = event.target.closest(".js-reset-form-range");
    const checkboxesRadios = formRange.querySelectorAll("input[type=checkbox], input[type=radio]");

    checkboxesRadios.forEach((input) => {
      input.checked = false;
    });

    const selects = formRange.querySelectorAll("select");
    selects.forEach((select) => {
      select.selectedIndex = 0;
    });

    const numberInputs = formRange.querySelectorAll("input[type=number]");
    numberInputs.forEach((input) => {
      input.value = "";
    });
    updateOutput();
  };
  const resetButtons = document.querySelectorAll(".js-reset-form-trigger");
  resetButtons.forEach((resetButton) => {
    resetButton.addEventListener("click", resetForm);
  });
});
