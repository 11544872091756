import "slick-carousel";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { collapseFortravelReviews } from "javascripts/webpage/collapse-fortravel-reviews";

document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelectorAll("#js-aggregated_hotels-show", "#js-hotels")) {
    return;
  }

  // 写真 / コメント / 口コミ
  const photos = ".js-slick-photos";
  $(photos).slick({
    arrows: true,
    cssEase: "linear",
    dots: false,
    fade: true,
    infinite: false,
    speed: 300,
  });
  [].forEach.call(document.querySelectorAll('[id^="target-photos-"], [id^="target-comment-photos-"], [id^="target-review-photos-"]'), (element) => {
    element.addEventListener("shown.bs.modal", function (event) {
      let $slickNode;
      $slickNode = $(event.target).find(photos);
      $slickNode.slick("setPosition");
      if (event.relatedTarget && event.relatedTarget.dataset) {
        return $slickNode.slick("slickGoTo", event.relatedTarget.dataset.index);
      }
    });
  });
  // 写真一覧のナビゲーションにClassを付与
  const photosLinks = document.querySelectorAll(".js-photos-link");
  if (photosLinks) {
    photosLinks.forEach((photoLink) => {
      photoLink.addEventListener("click", function () {
        photosLinks.forEach((link) => link.classList.remove("is-active"));
        this.classList.add("is-active");
      });
    });
  }
  // 別ページから遷移後の位置を調整
  let location = window.location.hash;
  const locationMatch = location.substring(1);
  if (locationMatch) {
    const navi = document.querySelector(".js-nav");
    const naviHeight = navi ? navi.clientHeight : "";
    const adjustedPosition = setInterval(() => {
      let target = document.getElementById(locationMatch);
      if (target) {
        // NOTE: 特定の過ごし方に遷移する場合も`target-section-model-case`の位置に遷移させる
        const modelCase = location.match(/^#target-model-case-tab-\d+$/)?.input;
        if (modelCase) {
          target = document.getElementById("target-section-model-case");
        }
        const position = window.pageYOffset + target.getBoundingClientRect().top - naviHeight;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
        location = history.replaceState(null, null, " ");
      }
      clearInterval(adjustedPosition);
    });
  }
  // floorguide：写真に遷移した時にModalを開く
  if (location === "#target-section-photo") {
    const photosModal = new bootstrap.Modal(document.getElementById("target-photos-category"));
    photosModal.show();
  }
  // floorguide：コース
  let coursePhotos = $(".js-slick-course-photos");
  if (coursePhotos) {
    coursePhotos.each((index) => {
      coursePhotos = $(`.js-slick-course-photos-${index + 1}`);
      const coursePhotosThumb = coursePhotos
        .clone()
        .attr("id", `target-course-photos-thumb-${index + 1}`)
        .attr("class", "p-hotels-floorguide-course-photo__list--thumb js-slick-course-photos-thumb")
        .insertAfter(coursePhotos);
      $(coursePhotos).slick({
        arrows: true,
        fade: true,
        autoplay: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: coursePhotosThumb,
      });
      $(coursePhotosThumb).slick({
        arrows: true,
        autoplay: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: coursePhotos,
      });
      $(".js-slick-course-photos-thumb p").remove();
    });
  }
  // floorguide：ホテルの1コマ
  $(".js-slick-onesence-photo").slick({
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    cssEase: "linear",
    dots: false,
    fade: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    zIndex: 1,
  });

  // 施設詳細の各プランをもっと見るで表示する
  const facilityPlanShowmore = document.querySelectorAll(".js-facility-plans-button");
  if (facilityPlanShowmore.length > 0) {
    facilityPlanShowmore.forEach((button) => {
      button.addEventListener("click", () => {
        button.style.display = "none";
        button.closest(".js-facility-plans-contens").classList.remove("p-hotels-show-facility-guide-plans--hide");
      });
    });
  }

  // 各口コミ内をすべて見るで表示する
  collapseFortravelReviews();
});
