function collapseFortravelReviews(wrapper = document) {
  const collapseMinLines = 4;
  wrapper.querySelectorAll(".js-fortravel-review-contents:not(.p-hotels-show-fortravel-review--hide)").forEach((element) => {
    const description = element.querySelector(".p-hotels-show-fortravel-review-user__description");
    if (description && description.scrollHeight >= collapseMinLines * parseFloat(window.getComputedStyle(description).lineHeight)) {
      element.classList.add("p-hotels-show-fortravel-review--hide");
      element.insertAdjacentHTML("beforeend", "<span class='p-hotels-show-fortravel-review-showmore-button js-collapse-fortravel-review' data-content='すべて見る'></span>");
    }
  });

  wrapper.querySelectorAll(".js-collapse-fortravel-review").forEach((element) => {
    element.addEventListener("click", () => {
      element.style.display = "none";
      element.closest(".js-fortravel-review-contents").classList.remove("p-hotels-show-fortravel-review--hide");
      const clickParams = element.closest(".js-fortravel-review-contents").querySelector(".js-click-count-collapse-fortravel-review");
      if (clickParams) {
        // eslint-disable-next-line no-undef
        onclickcatalyst_clickcount(clickParams.dataset.clickArea, clickParams.dataset.event);
      }
    });
  });
}

export { collapseFortravelReviews };
