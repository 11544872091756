document.addEventListener("DOMContentLoaded", () => {
  const DAY_IN_MS = 1000 * 60 * 60 * 24;
  const MAX_DAY = 13;
  const classDisabled = "js-calendar-disabled";
  const classCheckin = "is-checkin";
  const classCheckout = "is-checkout";
  const classRange = "is-range";
  const searchConditionsForm = document.querySelector(".js-search-conditions-form");
  const days = document.querySelectorAll(".js-calendar");
  const checkouts = document.querySelectorAll(".js-calendar-checkout");
  const staydates = document.querySelectorAll(".js-calendar-staydates");
  const weekDays = ["日", "月", "火", "水", "木", "金", "土"];
  const alertMaxDay = `チェックイン日からチェックアウト日までの期間は${MAX_DAY}日間を超えることはできません`;
  const unsetText = "未指定";
  let checkinDate = null;

  function parseDate(day) {
    return new Date(day.dataset.date);
  }

  function formatDateToHumanString(date) {
    return `${date.getFullYear()}/${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}(${weekDays[date.getDay()]})`;
  }

  function formatStaydates(checkinDate, checkoutDate) {
    var staydates = parseInt((checkoutDate - checkinDate) / 1000 / 60 / 60 / 24);
    return `${("0" + (checkinDate.getMonth() + 1)).slice(-2)}/${("0" + checkinDate.getDate()).slice(-2)}(${weekDays[checkinDate.getDay()]})${staydates}泊`;
  }

  function formatDateToSearch(date) {
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  function removeSelectedDays() {
    const selectDays = document.querySelectorAll(`.${classCheckin}, .${classCheckout}`);
    selectDays.forEach((day) => day.classList.remove(classCheckin, classCheckout));
  }

  function removeRange() {
    days.forEach((day) => day.classList.remove(classRange));
  }

  function calculateRange() {
    const activeCheckin = Array.from(days).findIndex((day) => day.classList.contains(classCheckin));
    const activeCheckout = Array.from(days).findIndex((day) => day.classList.contains(classCheckout));
    const range =
      activeCheckin <= activeCheckout
        ? Array.from({ length: activeCheckout - activeCheckin + 1 }, (_, index) => index + activeCheckin)
        : Array.from({ length: activeCheckin - activeCheckout + 1 }, (_, index) => activeCheckin - index);
    range.forEach((index) => days[index].classList.add(classRange));
  }

  function setCheckin(day) {
    removeSelectedDays();
    removeRange();
    day.classList.add(classCheckin);
    checkinDate = parseDate(day);
    if (searchConditionsForm.dataset.realtimeSearch === "false") {
      staydates.forEach((output) => {
        output.textContent = unsetText;
      });
    }
    document.querySelector(".js-calendar-checkin").textContent = formatDateToHumanString(checkinDate);
    document.querySelector(".js-search-checkin-input").value = formatDateToSearch(checkinDate);
    document.querySelector(".js-search-checkout-input").value = "";
  }

  function calendar(day) {
    if (day.classList.contains(classDisabled)) {
      day.classList.remove("c-calendar__day--today");
      return;
    }
    const checkin = document.querySelector(`.${classCheckin}`);
    const checkout = document.querySelector(`.${classCheckout}`);
    if (!checkout && checkin) {
      if (parseDate(day) <= checkinDate) {
        setCheckin(day);
        return;
      }
      if ((parseDate(day) - checkinDate) / DAY_IN_MS > MAX_DAY) {
        alert(alertMaxDay);
        return;
      }
      day.classList.add(classCheckout);
      calculateRange();
      let checkoutDate = parseDate(day);
      if (searchConditionsForm.dataset.realtimeSearch === "false") {
        staydates.forEach((output) => {
          output.textContent = formatStaydates(checkinDate, checkoutDate);
        });
      }
      checkouts.forEach((checkout) => {
        checkout.textContent = formatDateToHumanString(checkoutDate);
      });
      document.querySelector(".js-search-checkout-input").value = formatDateToSearch(checkoutDate);
    } else {
      setCheckin(day);
    }
  }

  days.forEach((day) => day.addEventListener("click", () => calendar(day)));
  days.forEach((day) => {
    if (day.classList.contains(classDisabled)) {
      day.classList.remove("c-calendar__day--today");
    }
  });
  const calendarPosition = document.querySelector(".js-calendar-position");
  if (calendarPosition) {
    calendarPosition.addEventListener("click", () => {
      const target = document.getElementById("js-target-calendar-position");
      if (target) {
        target.scrollIntoView({ block: "center" });
      }
    });
  }
});
