const classRange = "js-destination-checkbox-range";
const classPrefecture = "js-destination-checkbox-prefecture";
const classArea = "js-destination-checkbox-area";
let searchConditionsForm;

function updateOutput() {
  let checkboxRanges = document.getElementsByClassName(classRange);
  document.querySelectorAll(".js-destination-checkbox-output").forEach((output) => {
    const prefecturesArray = [];
    const areasArray = [];
    const outputPrefecturesArray = [];
    const outputAreasArray = [];
    Array.from(checkboxRanges).forEach((checkboxRange) => {
      let prefectureCheckbox = checkboxRange.querySelector(`.${classPrefecture}`);
      if (prefectureCheckbox.checked) {
        outputPrefecturesArray.push(prefectureCheckbox.nextElementSibling.textContent);
        prefecturesArray.push(prefectureCheckbox.value);
      } else {
        let areaCheckboxes = checkboxRange.querySelectorAll(`.${classArea}`);
        Array.from(areaCheckboxes).forEach((checkbox) => {
          if (checkbox.checked) {
            outputAreasArray.push(checkbox.nextElementSibling.textContent);
            areasArray.push(checkbox.value);
          }
        });
      }
    });
    if (searchConditionsForm.dataset.realtimeSearch === "false") {
      let outputArray = outputPrefecturesArray.concat(outputAreasArray);
      output.textContent = outputArray.length > 0 ? outputArray.join("、") : "目的地を設定してください";
    }
    document.querySelector(`.js-search-destination-prefectures-input`).value = prefecturesArray;
    document.querySelector(`.js-search-destination-areas-input`).value = areasArray;
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const MAX_DESTINATION = 30;
  const alertMaxDay = `選択できる目的地は${MAX_DESTINATION}個までです（都道府県全域の選択は1個としてカウントします）`;
  const classChecked = ".js-destination-checkbox-checked";
  searchConditionsForm = document.querySelector(".js-search-conditions-form");
  function updateCountAndDisplayAlert() {
    let count = 0;
    const prefectureCheckboxes = document.querySelectorAll(`.${classPrefecture}`);

    prefectureCheckboxes.forEach((prefectureCheckbox) => {
      const checkboxRange = prefectureCheckbox.closest(`.${classRange}`);
      if (prefectureCheckbox.checked) {
        count += 1;
      } else {
        checkboxRange.querySelectorAll(`.${classArea}:checked`).forEach(() => {
          count += 1;
        });
      }
    });
    return count;
  }

  let prefectureCheckboxes = document.getElementsByClassName(classPrefecture);
  Array.from(prefectureCheckboxes).forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      let prefectureIsChecked = event.target.checked;
      let detailsElement = event.target.closest(`.${classRange}`);
      detailsElement.querySelectorAll(classChecked).forEach((checkbox) => {
        checkbox.checked = prefectureIsChecked;
      });
      if (updateCountAndDisplayAlert() > MAX_DESTINATION) {
        alert(alertMaxDay);
        event.preventDefault();
        event.target.checked = !prefectureIsChecked;
        detailsElement.querySelectorAll(classChecked).forEach((checkbox) => {
          checkbox.checked = !prefectureIsChecked;
        });
      }
      updateOutput();
    });
  });

  let areaCheckboxes = document.getElementsByClassName(classArea);
  Array.from(areaCheckboxes).forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      let areaIsChecked = event.target.checked;
      const areasList = event.currentTarget.closest(`.${classRange}`);
      const prefectureBeforeChecked = areasList.querySelector(`.${classPrefecture}`).checked;
      areasList.querySelector(`.${classPrefecture}`).checked = Array.from(areasList.querySelectorAll(`.${classArea}`)).every((checkbox) => checkbox.checked);
      if (updateCountAndDisplayAlert() > MAX_DESTINATION) {
        alert(alertMaxDay);
        event.preventDefault();
        event.target.checked = !areaIsChecked;
        areasList.querySelector(`.${classPrefecture}`).checked = prefectureBeforeChecked;
      }
      updateOutput();
    });
  });
});

export { updateOutput };
