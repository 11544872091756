import Cookies from "js-cookie";

export default () => {
  const COOKIE_KEY = "hide_join_member";
  const joinFloating = document.getElementById("js-join-floating");
  const joinHeader = document.getElementById("js-join-header");
  if (Cookies.get(COOKIE_KEY)) {
    joinFloating?.remove();
    joinHeader?.remove();
    return;
  }
  const closeFloatingButton = document.getElementById("js-join-floating-close");
  const closeHeaderButton = document.getElementById("js-join-header-close");
  const closeHandler = (event) => {
    Cookies.set(COOKIE_KEY, "1", { expires: 7 });
    joinFloating?.remove();
    joinHeader?.remove();
    event.preventDefault();
  };
  if (closeFloatingButton) {
    closeFloatingButton.addEventListener("click", closeHandler);
  }
  if (closeHeaderButton) {
    closeHeaderButton.addEventListener("click", closeHandler);
  }
};
